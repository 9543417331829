import React, { useState, useEffect } from "react";
import axios from "axios";

const AdminPanel = () => {
  const [jobTitle, setJobTitle] = useState("");
  const [salaryRange, setSalaryRange] = useState("");
  const [applyLink, setApplyLink] = useState("");
  const [jobs, setJobs] = useState([]);
  const [editingJobId, setEditingJobId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    fetchJobs();
  }, []);
 
  // Fetch jobs from backend
  const fetchJobs = async () => {
    try {
      const response = await axios.get("https://result.govtresult24.com/api/jobs");
      setJobs(response.data);
    } catch (error) {
      setError("Error fetching jobs");
      console.error("Error fetching jobs", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const jobData = { jobTitle, salaryRange, applyLink };

    try {
      if (editingJobId) {
        await axios.put(`https://result.govtresult24.com/api/jobs/${editingJobId}`, jobData);
      } else {
        await axios.post("https://result.govtresult24.com/api/jobs", jobData);
      }
      resetForm();
      fetchJobs(); // Refresh job list
    } catch (err) {
      setError("Error saving job data");
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const resetForm = () => {
    setJobTitle("");

    setEditingJobId(null);
  };

  const handleEdit = (job) => {
    setJobTitle(job.jobTitle);
  
    setEditingJobId(job._id);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this job?")) {
      try {
        await axios.delete(`https://result.govtresult24.com/api/jobs/${id}`);
        fetchJobs(); // Refresh job list after deletion
      } catch (error) {
        setError("Error deleting job");
        console.error(error);
      }
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="mb-4">
          <label className="block text-gray-700">Job Title:</label>
          <input
            type="text"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            className="border rounded p-2 w-full"
            required
          />
        </div>
        {/* <div className="mb-4">
          <label className="block text-gray-700">Salary Range:</label>
          <input
            type="text"
            value={salaryRange}
            onChange={(e) => setSalaryRange(e.target.value)}
            className="border rounded p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Apply Link:</label>
          <input
            type="text"
            value={applyLink}
            onChange={(e) => setApplyLink(e.target.value)}
            className="border rounded p-2 w-full"
            required
          />
        </div> */}
        <button type="submit" className="bg-blue-500 text-white rounded p-2" disabled={loading}>
          {loading ? 'Processing...' : (editingJobId ? 'Update Job' : 'Add Job')}
        </button>
        {error && <p className="text-red-500">{error}</p>}
      </form>

      <h2 className="text-xl font-bold mb-4">Job Listings</h2>
      <ul>
        {jobs.map((job) => (
          <li key={job._id} className="border p-2 mb-2">
            <h3 className="font-bold">{job.jobTitle}</h3>
            {/* <p>Salary: {job.salaryRange}</p>
            <p>
              Apply: <a href={job.applyLink} target="_blank" rel="noopener noreferrer">{job.applyLink}</a>
            </p> */}
            {/* <h4 className="font-semibold">Notifications:</h4> */}
            <ul>
              {job.notifications.map((notification, idx) => (
                <li key={idx} className="bg-gray-100 p-2 my-1 rounded">
                  <h5 className="font-bold">{notification.nameOfPost}</h5>
                  <p>{notification.shortInfo}</p>
                  <p>Post Date: {new Date(notification.postDate).toLocaleDateString()}</p>
                </li>
              ))}
            </ul>
            <button onClick={() => handleEdit(job)} className="bg-yellow-500 text-white rounded p-1 mr-2">Edit</button>
            <button onClick={() => handleDelete(job._id)} className="bg-red-500 text-white rounded p-1">Delete</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminPanel;
