import { Navigate, useRoutes } from 'react-router-dom';
import User_Layout from './componetns/User_Layout';
import HeroSection from './componetns/HeroSection';
import LatestJob from './componetns/LatestJob';
import Result from './componetns/Result';
import AdmitCardList from './componetns/AdmitCardList';
import ShowLatestJob from './componetns/unuse/ShowLatestJob';
import JobNotificationsPage from './componetns/LatestJobInfo';
import Admin_Layout from './AdminPanel/Admin_Layout';
import Admin_Home from './AdminPanel/Admin_Home';
import AdminPanel from './AdminPanel/Data/AddJobs';
import AdminPanel1 from './AdminPanel/Data/AddJobInfo';
// import ApplicationFeeAdmin from './AdminPanel/Data/unuse/ApplicationFeeAdmin';
import NDAAdminPanel from './AdminPanel/Data/unuse/NDAAdminPanel';
import LinkAdmin from './AdminPanel/Data/unuse/LinkAdmin';
import ResultAdmin from './AdminPanel/Data/ResultAdmin';
import AdmitcardAdmin from './AdminPanel/Data/AddAdmitCard';
// import DataAdminPanel from './AdminPanel/Data/DataAdminPanel';
import Itemadmin from './AdminPanel/Data/AddJobUsefullLinks';
import DynamicTable from './AdminPanel/Data/AddJobDynamicTable';
import Admin_Login from './AdminPanel/Admin_Login';
import { useState } from 'react';
import Admission from './componetns/Admission';
import Syllabus from './componetns/Syllabus';
import Answer from './componetns/AnswerKey';
import Job2 from './AdminPanel/Data/AddAdmission';
import Addjobinf02 from './AdminPanel/Data/AddAdmissioninfo';
// import AdmissionInfo from './AdminPanel/Data/AdmissionInfo';
import JobNotificationsPage2 from './componetns/AdmissionInfo';
import Itemadminn from './AdminPanel/Data/AddJobUsefullLinks copy';

function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };
  // Define the routes using useRoutes
  let routes = useRoutes([
    {
      path: "/",
      element: <User_Layout />, // Layout component for your user pages
      children: [
        { index: true, element: <HeroSection /> },
        { path: "/latest-job", element: <LatestJob /> },
        { path: "/result", element: <Result /> },
        { path: "/AdmitCard-List", element: <AdmitCardList /> },
        // { path: "/answer-key", element: <AnswerKey /> },
        { path: "/ShowLatestJob", element: <ShowLatestJob /> },
        // { path: "/VacancyDetails", element: <VacancyDetails /> },
        // { path: "/StatesList", element: <StatesList /> },
        { path: "/job-notifications/:jobTitle", element: <JobNotificationsPage /> },
        { path: "/job-notifications2/:jobTitle2", element: <JobNotificationsPage2 /> },
        { path: "/Admission", element: <Admission /> },
        // { path: "/Admissioninfo", element: <AdmissionInfo /> },
        { path: "/syllabus", element: <Syllabus /> },
        { path: "/Answer", element: <Answer /> },
        // { path: "/VideosPage", element: <VideosPage /> },

        // { path: "/SimpleForm", element: <SimpleForm /> }
      ]
    },
    {
      path: "/admin",
      element: isAuthenticated ? <Admin_Layout /> : <Navigate to="/login" />, // Redirect to login if not authenticated
      children: [
        { index: true, element: <Admin_Home /> },
        { path: "AdminPanel", element: <AdminPanel /> },
        { path: "AdminPanel1", element: <AdminPanel1 /> },
        // { path: "ApplicationFeeAdmin", element: <ApplicationFeeAdmin /> },
        { path: "NDAAdminPanel", element: <NDAAdminPanel /> },
        { path: "LinkAdmin", element: <LinkAdmin /> },
        { path: "ResultAdmin", element: <ResultAdmin /> },
        { path: "AdmitcardAdmin", element: <AdmitcardAdmin /> },
        // { path: "DataAdminPanel", element: <DataAdminPanel /> },
        { path: "Itemadmin", element: <Itemadmin /> },
        { path: "DynamicTable", element: <DynamicTable /> },
        { path: "job2", element: <Job2 /> },
        { path: "AddAdmision", element: <Addjobinf02 /> },
        { path: "Itemadminn", element: <Itemadminn /> },
      ]
    },
    {
      path: "/login",
      element: <Admin_Login onLogin={handleLogin} />,
    }
  ]);

  return (
    <div className="App">
      {routes}
    </div>
  );
}

export default App;
