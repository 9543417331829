import React, { useState, useEffect } from "react";
import axios from "axios";

const Addjobinf02 = () => {
  const [formData, setFormData] = useState({
    nameOfPost: "",
    postDate: "",
    shortInfo: "",
    requirementTitle: "",
    examTitle: "",
    websiteLink: "",
    applicationBegin: "",
    lastDateApply: "",
    lastDatePayExamFee: "",
    examDate: "",
    admitCardAvailable: "",
    generalFee: "",
    scStPhFee: "",
    additionalInfo: "",
    Title2: "",
    StartDate: "",
    EndDate: "",
    AgeRelaxation: "",
    selectedJobTitle2: "",
  });

  const [jobs, setJobs] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editNotificationId, setEditNotificationId] = useState(null);
  const [dynamicInputs, setDynamicInputs] = useState([
    { id: Date.now(), value: "" },
  ]);
  const [additionalDates, setAdditionalDates] = useState([]); // For dynamic date inputs
  const [postRecruitmentInputs, setPostRecruitmentInputs] = useState([]);
  
  useEffect(() => {
    fetchJobs();
  }, []);

  useEffect(() => {
    if (formData.selectedJobTitle2) {
      fetchNotifications(formData.selectedJobTitle2);
    }
  }, [formData.selectedJobTitle2]);

  const fetchJobs = async () => {
    try {
      const response = await axios.get("https://result.govtresult24.com/api/job2");
      setJobs(response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error.message);
    }
  };

  const fetchNotifications = async (title2) => {
    try {
      const response = await axios.get(
        `https://result.govtresult24.com/api/job2/notifications2/${title2}`
      );

      // Log the raw response data to inspect its structure
      console.log("Fetched Response Data:", response.data);

      // Map through the notifications to format and check for undefined values
      const formattedNotifications = response.data.map((notification) => ({
        ...notification,
        postDate: formatDateFortext(notification.postDate),
        applicationBegin: formatDateFortext(notification.applicationBegin),
        lastDateApply: formatDateFortext(notification.lastDateApply),
        lastDatePayExamFee: formatDateFortext(notification.lastDatePayExamFee),
        examDate: formatDateFortext(notification.examDate),
        admitCardAvailable: notification.admitCardAvailable
          ? formatDateFortext(notification.admitCardAvailable)
          : "", // Set to empty string if it's undefined
        additionalDates: notification.additionalDates || [], // Ensure it's being mapped
      }));

      // Log formatted notifications for debugging
      console.log("Formatted Notifications:", formattedNotifications);

      // Set the formatted notifications to state
      setNotifications(formattedNotifications);
    } catch (error) {
      console.error("Error fetching notifications:", error.message);
    }
  };

  const formatDateForInput = (date) => {
    if (!date || date === "Not Available") return ""; // Return empty string if date is invalid or not available
    const d = new Date(date);
    if (isNaN(d)) return ""; // Empty string if date is invalid
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  const formatDateFortext = (date) => {
    if (!date) return "Not Available";
    return date; // Agar valid date ho, toh same date return karo
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value, // Dynamically update the state based on the input's name
    }));
  };

  const handleDynamicInputChange = (e, id) => {
    const { value } = e.target;
    setDynamicInputs((prev) =>
      prev.map((input) =>
        input.id === id ? { ...input, value: value } : input
      )
    );
  };

  const addDynamicInput = () => {
    setDynamicInputs((prev) => [...prev, { id: Date.now(), value: "" }]);
  };

  // New method to add an additional date input dynamically
  const handleAddDate = () => {
    setAdditionalDates((prev) => [...prev, { dateValue: "" }]);
  };

  const handleAdditionalChange = (index, e) => {
    const { value } = e.target;
    const updatedDates = [...additionalDates];
    updatedDates[index].dateValue = value;
    setAdditionalDates(updatedDates);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const selectedJob = jobs.find(
      (job) => job.jobTitle2 === formData.selectedJobTitle2
    );
    if (!selectedJob) {
      alert("Please select a valid job title2.");
      return;
    }
  
    const notificationData = {
      ...formData,
      dynamicInputs: dynamicInputs.map((input) => input.value),
      postRecruitmentInputs: postRecruitmentInputs.map((input) => input.value), // Add this line to include postRecruitmentInputs
      additionalDates, // Include additional dates in the data
    };
  
    try {
      if (isEditing) {
        const response = await axios.put(
          `https://result.govtresult24.com/api/job2/${selectedJob._id}/notifications2/${editNotificationId}`,
          notificationData
        );
        setNotifications((prev) =>
          prev.map((notification) =>
            notification._id === editNotificationId
              ? response.data
              : notification
          )
        );
      } else {
        const response = await axios.post(
          `https://result.govtresult24.com/api/job2/${selectedJob._id}/notifications2`,
          notificationData
        );
        setNotifications((prev) => [...prev, response.data]);
      }
  
      resetForm();
    } catch (error) {
      console.error("Error saving notification:", error.message);
    }
  };
  
  const handleDelete = async (notificationId) => {
    const selectedJob = jobs.find(
      (job) => job.jobTitle2 === formData.selectedJobTitle2
    );
    if (!selectedJob) return;

    try {
      await axios.delete(
        `https://result.govtresult24.com/api/job2/${selectedJob._id}/notifications2/${notificationId}`
      );
      setNotifications((prev) => prev.filter((n) => n._id !== notificationId));
    } catch (error) {
      console.error("Error deleting notification:", error.message);
    }
  };

  const handleEdit = (notification) => {
    console.log("Editing notification:", notification);
    setIsEditing(true);
    setEditNotificationId(notification._id);
  
    console.log("Admit Card Available Value:", notification.admitCardAvailable); // Log it for debugging
  
    // Update formData with formatted dates
    setFormData({
      ...notification,
      selectedJobTitle2: formData.selectedJobTitle2,
      postDate: notification.postDate,
      applicationBegin: notification.applicationBegin,
      lastDateApply: notification.lastDateApply,
      lastDatePayExamFee: notification.lastDatePayExamFee,
      examDate: notification.examDate,
      admitCardAvailable: notification.admitCardAvailable || "", // Set default if undefined
    });
  
    setDynamicInputs(
      notification.dynamicInputs.map((input, index) => ({
        id: Date.now() + index,
        value: input,
      }))
    );
  
    setAdditionalDates(notification.additionalDates || []);
  
    // Ensure postRecruitmentInputs is set correctly
    setPostRecruitmentInputs(
      notification.postRecruitmentInputs
        ? notification.postRecruitmentInputs.map((input, index) => ({
            id: index + 1,
            value: input,
          }))
        : []
    );
  };
  
  const resetForm = () => {
    setIsEditing(false);
    setEditNotificationId(null);
    setDynamicInputs([{ id: Date.now(), value: "" }]);
    setFormData((prev) => ({
      ...prev,
      nameOfPost: "",
      postDate: "",
      shortInfo: "",
      requirementTitle: "",
      examTitle: "",
      websiteLink: "",
      applicationBegin: "",
      lastDateApply: "",
      lastDatePayExamFee: "",
      examDate: "",
      admitCardAvailable: "",
      generalFee: "",
      scStPhFee: "",
      additionalInfo: "",
      Title2: "",
      StartDate: "",
      EndDate: "",
      AgeRelaxation: "",
    }));
  };


  const addPostRecruitmentInput = () => {
    setPostRecruitmentInputs([
      ...postRecruitmentInputs,
      { id: postRecruitmentInputs.length + 1, value: "" },
    ]);
  };
  const updateInputValue = (e, id) => {
    const { value } = e.target;
    setPostRecruitmentInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, value } : input
      )
    );
  };
  
  return (
    <>
      <form onSubmit={handleSubmit} className="p-6">
        <h2 className="text-2xl mb-4">Add Job Info</h2>
        <select
          name="selectedJobTitle2"
          value={formData.selectedJobTitle2}
          onChange={handleChange}
          required
          className="mb-4 p-2 border rounded"
        >
          <option value="">Select Job Title2</option>
          {jobs.map((job) => (
            <option key={job._id} value={job.jobTitle2}>
              {job.jobTitle2}
            </option>
          ))}
        </select>

        {/* Add other fields for notification details */}
        <div className="mb-4">
          <label className="block">Name of Post:</label>
          <input
            type="text"
            name="nameOfPost"
            value={formData.nameOfPost}
            onChange={handleChange}
            className="border rounded p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block">Post Date:</label>
          <input
            type="text"
            name="postDate"
            value={formData.postDate}
            onChange={handleChange}
            className="border rounded p-2 w-full"
            required
          />

          <textarea
            name="shortInfo"
            placeholder="Short Info"
            value={formData.shortInfo}
            onChange={handleChange}
            className="border rounded p-2 w-full"
            rows="3"
            required
          ></textarea>

          {/* <div className="section">
            <h2 className="font-bold text-xl">
              NICL Assistant Notifications Admin Panel
            </h2>
            <input
              type="text"
              name="requirementTitle"
              value={formData.requirementTitle}
              onChange={handleChange}
              placeholder="Requirement Title"
              className="w-full p-2 border"
            />
            <input
              type="text"
              name="examTitle"
              value={formData.examTitle}
              onChange={handleChange}
              placeholder="Exam Title"
              className="w-full p-2 border"
            />
            <input
              type="url"
              name="websiteLink"
              value={formData.websiteLink}
              onChange={handleChange}
              placeholder="Website Link"
              className="w-full p-2 border"
            />
          </div> */}

          <div className="section">
            <h2 className="font-bold text-xl">Important Dates Form</h2>
            <input
              type="text"
              name="applicationBegin"
              value={formData.applicationBegin}
              onChange={handleChange}
              placeholder="Application Begin"
              className="w-full p-2 border"
            />
            <input
              type="text"
              name="lastDateApply"
              value={formData.lastDateApply}
              onChange={handleChange}
              placeholder="Last Date for Apply Online"
              className="w-full p-2 border"
            />
            <input
              type="text"
              name="lastDatePayExamFee"
              value={formData.lastDatePayExamFee}
              onChange={handleChange}
              placeholder="Last Date Pay Exam Fee"
              className="w-full p-2 border"
            />
            <input
              type="text"
              name="examDate"
              value={formData.examDate}
              onChange={handleChange}
              placeholder="Exam Date"
              className="w-full p-2 border"
            />

            <input
              type="text"
              name="admitCardAvailable"
              value={formData.admitCardAvailable || ""} // Ensure the value is set correctly
              onChange={handleChange}
              placeholder="Admit Card Available"
              className="w-full p-2 border"
            />
          </div>

          {/* Additional Dates Section */}
          <div>
            {/* <h3 className="font-bold text-lg mt-4">Additional Dates</h3> */}
            {additionalDates.map((date, index) => (
              <div key={index} className="flex space-x-2">
                <input
                  type="text"
                  name="dateValue"
                  value={date.dateValue}
                  onChange={(e) => handleAdditionalChange(index, e)}
                  placeholder="Date"
                  className="w-1/2 p-2 border"
                />
              </div>
            ))}
            <button
              type="button"
              onClick={handleAddDate}
              className="mt-4 p-2 bg-blue-500 text-white"
            >
              Add New Date
            </button>
          </div>

          <div className="section">
            <h2 className="font-bold text-xl">Application Fee Admin Panel</h2>
            <input
              type="text"
              name="generalFee"
              value={formData.generalFee}
              onChange={handleChange}
              placeholder="General / OBC / EWS Fee"
              className="w-full p-2 border"
            />
            <input
              type="text"
              name="scStPhFee"
              value={formData.scStPhFee}
              onChange={handleChange}
              placeholder="SC / ST / PH Fee"
              className="w-full p-2 border"
            />
            <textarea
              name="additionalInfo"
              value={formData.additionalInfo}
              onChange={handleChange}
              placeholder="Additional Information"
              className="w-full p-2 border"
            />

            {/* Dynamic Inputs Section */}
            <div className="section">
         
              {dynamicInputs && dynamicInputs.length > 0 ? (
                dynamicInputs.map((input) =>
                  input ? (
                    <div key={input.id} className="flex gap-2 mb-2">
                      <input
                        type="text"
                        name={`dynamicInput-${input.id}`}
                        value={input.value || ""} // Fallback value if input.value is undefined or null
                        onChange={(e) => handleDynamicInputChange(e, input.id)}
                        placeholder="Enter value"
                        className="w-full p-2 border"
                      />
                    </div>
                  ) : null
                )
              ) : (
                <p>No inputs available</p>
              )}
              <button
                type="button"
                onClick={addDynamicInput}
                className="mt-2 p-2 bg-blue-500 text-white rounded"
              >
                Add More Fields
              </button>
            </div>

            {/* Other form sections */}
            {/* <button type="submit" className="mt-4 p-2 bg-green-500 text-white rounded">
          Submit
        </button> */}
          </div>

          <div className="section">
        <h2 className="font-bold text-xl">Post Recruitment</h2>
        <input
          type="text"
          name="Title2"
          value={formData.Title2}
          onChange={handleChange}
          placeholder="Title2"
          className="w-full p-2 border"
        />
        <input
          type="text"
          name="StartDate"
          value={formData.StartDate}
          onChange={handleChange}
          placeholder="Start Date"
          className="w-full p-2 border"
        />
        <input
          type="text"
          name="EndDate"
          value={formData.EndDate}
          onChange={handleChange}
          placeholder="End Date"
          className="w-full p-2 border"
        />
        <input
          type="text"
          name="AgeRelaxation"
          value={formData.AgeRelaxation}
          onChange={handleChange}
          placeholder="Age Relaxation"
          className="w-full p-2 border"
        />

        {/* Dynamic Inputs for Post Recruitment Section */}
        <div className="section">
      {postRecruitmentInputs.length > 0 ? (
        postRecruitmentInputs.map((input) => (
          <div key={input.id} className="flex gap-2 mb-2">
            <input
              type="text"
              name={`postRecruitmentInput-${input.id}`}
              value={input.value || ""}
              onChange={(e) => updateInputValue(e, input.id)} // Using the new function name here
              placeholder="Enter value"
              className="w-full p-2 border"
            />
          </div>
        ))
      ) : (
        <p>No inputs available</p>
      )}
      <button
        type="button"
        onClick={addPostRecruitmentInput}
        className="mt-2 p-2 bg-blue-500 text-white rounded"
      >
        Add  Fields
      </button>
    </div>
      </div>
        </div>
        {/* Add other form fields as necessary */}

        <button type="submit" className="bg-blue-500 text-white rounded p-2">
          Submit Notification
        </button>
      </form>

      <div className="container mx-auto my-8 p-6 bg-white shadow-lg rounded-lg">
        <h2 className="text-3xl font-bold mb-4 text-center text-gray-800">
          Job Notifications
        </h2>
        {notifications.length > 0 ? (
          <ul className="space-y-4">
            {notifications.map((notification) => (
              <li
                key={notification._id}
                className="p-4 bg-gray-100 rounded-lg shadow-md hover:shadow-lg transition-all duration-200"
              >
                <div className="text-gray-800">
                  <strong className="text-xl">{notification.nameOfPost}</strong>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Post Date:</strong> {notification.postDate}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Short Info:</strong> {notification.shortInfo}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Requirement Title2:</strong>{" "}
                    {notification.requirementTitle2}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Exam Title:</strong> {notification.examTitle}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Website Link:</strong>{" "}
                    <a
                      href={notification.websiteLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {notification.websiteLink}
                    </a>
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Application Begin:</strong>{" "}
                    {notification.applicationBegin}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Last Date to Apply:</strong>{" "}
                    {notification.lastDateApply}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Last Date to Pay Exam Fee:</strong>{" "}
                    {notification.lastDatePayExamFee}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Exam Date:</strong> {notification.examDate}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Admit Card Available:</strong>{" "}
                    {notification.admitCardAvailable || "Not Available"}
                  </p>
                  <div>
                    <h3>Job Notifications</h3>
                    <ul>
                      {notifications.map((notification) => (
                        <li key={notification._id}>
                          <p>{notification.nameOfPost}</p>
                          {/* Render other fields */}
                          <div>
                            <strong>Additional Dates:</strong>
                            <ul>
                              {notification.additionalDates &&
                              notification.additionalDates.length > 0 ? (
                                notification.additionalDates.map(
                                  (date, index) => (
                                    <li key={index}>{date.dateValue}</li> // Render each date value
                                  )
                                )
                              ) : (
                                <li>No additional dates available</li>
                              )}
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>General Fee:</strong> {notification.generalFee}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>SC/ST/PH Fee:</strong> {notification.scStPhFee}
                  </p>
                  {notifications.map((notification) => (
                    <div key={notification._id}>
                      <h3>{notification.nameOfPost}</h3>
                      <ul>
                        {notification.dynamicInputs?.map((input, idx) => (
                          <li key={idx}>{input}</li> // Render each dynamic input
                        ))}
                      </ul>
                    </div>
                  ))}

                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Additional Info:</strong>{" "}
                    {notification.additionalInfo}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Title:</strong> {notification.Title}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Start Date:</strong> {notification.StartDate}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>End Date:</strong> {notification.EndDate}
                  </p>
                  <p className="text-sm text-gray-600 mt-1">
                    <strong>Age Relaxation:</strong>{" "}
                    {notification.AgeRelaxation}
                  </p>

                  {notifications.map((notification) => (
                    <div key={notification._id}>
                      <h3>{notification.nameOfPost}</h3>
                      <ul>
                        {notification.postRecruitmentInputs?.map((input, idx) => (
                          <li key={idx}>{input}</li> // Render each dynamic input
                        ))}
                      </ul>
                    </div>
                  ))}

                  {/* Edit and Delete buttons */}
                  <div className="mt-4 flex space-x-4">
                    <button
                      onClick={() => handleEdit(notification)}
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700 transition-all duration-200"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(notification._id)}
                      className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-700 transition-all duration-200"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500 text-center">No notifications found.</p>
        )}
      </div>
    </>
  );
};

export default Addjobinf02;
