import React, { useState, useEffect } from "react";

function LinkAdmin() {
  const [link, setLink] = useState(""); // State for the link input
  const [linksList, setLinksList] = useState([]); // State for the list of links
  const [editingId, setEditingId] = useState(null); // Track the link being edited
  const [editingUrl, setEditingUrl] = useState(""); // Track the editing URL

  // Function to handle adding a new link
  const handleAddLink = () => {
    if (link) {
      const newLink = { id: Date.now(), url: link }; // Simulate link object
      setLinksList([...linksList, newLink]); // Add the new link to the list
      setLink(""); // Clear the input field
    }
  };

  // Function to handle link editing
  const handleEditClick = (item) => {
    setEditingId(item.id); // Set the editing ID
    setEditingUrl(item.url); // Set the URL to edit
  }; 

  // Function to update a link
  const handleUpdateLink = () => {
    if (editingUrl) {
      setLinksList(
        linksList.map((item) =>
          item.id === editingId ? { ...item, url: editingUrl } : item
        )
      ); // Update the link in the list
      setEditingId(null); // Reset editing state
      setEditingUrl(""); // Clear the input
    }
  };

  // Function to delete a link
  const handleDeleteLink = (id) => {
    setLinksList(linksList.filter((item) => item.id !== id)); // Remove the deleted link from the list
  };

  return (
    <div className="container mx-auto p-4 mt-10">
      <h1 className="text-4xl font-bold mb-6 text-center text-blue-500">Link Manager</h1>
      <hr className="w-full border-t-2 mb-6" />

      {/* Input field for the link only */}
      <div className="mb-6 flex justify-center">
        <input
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Enter link URL..."
          className="border border-gray-300 rounded py-2 px-4"
          style={{ width: "300px" }}
        />
        <button
          onClick={handleAddLink}
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
        >
          Add Link
        </button>
      </div>

      {/* Displaying the list of links */}
      <div className="mt-4">
        <h2 className="text-2xl font-bold mb-2">Links List</h2>
        <ul className="list-disc list-inside">
          {linksList.map((item) => (
            <li key={item.id} className="mb-1 flex items-center justify-between">
              <a href={item.url} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                {item.url} {/* Display the URL only */}
              </a>
              <div>
                <button
                  onClick={() => handleEditClick(item)}
                  className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-1 px-2 rounded mr-2"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteLink(item.id)}
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded"
                >
                  Delete
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Edit Section */}
      {editingId && (
        <div className="mt-6 flex justify-center">
          <input
            type="text"
            value={editingUrl}
            onChange={(e) => setEditingUrl(e.target.value)}
            placeholder="Edit link URL..."
            className="border border-gray-300 rounded py-2 px-4"
            style={{ width: "300px" }}
          />
          <button
            onClick={handleUpdateLink}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2"
          >
            Update Link
          </button>
        </div>
      )}
    </div>
  );
}

export default LinkAdmin;
