import React from "react";
import { FaWhatsapp, FaTelegramPlane, FaYoutube } from "react-icons/fa";
import HeroLatestJob from "./Hero Component/HeroLatestJob";
import HeroResult from "./Hero Component/HeroResult";
import HeroAdmitCardList from "./Hero Component/HeroAdmitCardList";
import HeroSyllabus from "./Hero Component/HeroSyllabus";
import HeroAnswerKey from "./Hero Component/HeroAnswerKey";
import HeroAdmission from "./Hero Component/HeroAdmission";

function HeroSection() {
  return (
    <section className="relative py-16 bg-gradient-to-r from-green-200 via-yellow-100 to-yellow-50 overflow-hidden">
      {/* Animated Background Shapes */}
      <div className="absolute top-0 left-1/4 w-[300px] h-[300px] md:w-[400px] md:h-[400px] bg-gradient-to-r from-yellow-400 via-red-300 to-pink-300 rounded-full opacity-50 blur-3xl animate-pulse"></div>
      <div className="absolute bottom-0 right-1/3 w-[250px] h-[250px] md:w-[350px] md:h-[350px] bg-gradient-to-r from-blue-400 to-purple-300 rounded-full opacity-40 blur-3xl animate-bounce"></div>

      {/* Main Content */}
      <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center relative px-6 md:px-12 text-center md:text-left">
        {/* Text Section */}
        <div className="space-y-6 md:w-1/2">
          <h1 className="text-3xl md:text-5xl font-extrabold text-gray-900 leading-tight drop-shadow-lg">
            Stay Updated, Stay Informed
          </h1>
          <p className="text-base md:text-lg text-gray-700 font-medium leading-relaxed">
            Your one-stop solution for government job updates, exam results,
            admit cards, and more.
          </p>
          <p className="text-sm md:text-base text-gray-500 font-medium">
            Join our growing community and never miss an update!
          </p>

          {/* Buttons */}
          <div className="flex flex-wrap justify-center md:justify-start gap-4">
            <a
              href="https://chat.whatsapp.com/KkHdxGTLIYmEUlEfF9AJrV"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center bg-gradient-to-r from-green-500 to-green-600 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg shadow-lg hover:scale-105 transform transition duration-300">
                <FaWhatsapp className="mr-2" size={20} /> WhatsApp
              </button>
            </a>

            <a
              href="https://t.me/+EBtySYhBB7o2MjI1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center bg-gradient-to-r from-blue-500 to-blue-600 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg shadow-lg hover:scale-105 transform transition duration-300">
                <FaTelegramPlane className="mr-2" size={20} /> Telegram
              </button>
            </a>

            <a
              href="https://youtube.com/@govtresult24?si=4kln94OzHuOL1V24"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="flex items-center bg-gradient-to-r from-red-500 to-red-600 text-white px-4 md:px-6 py-2 md:py-3 rounded-lg shadow-lg hover:scale-105 transform transition duration-300">
                <FaYoutube className="mr-2" size={20} /> YouTube
              </button>
            </a>
          </div>
        </div>

        {/* Image Section */}
        {/* <div className="mt-10 md:mt-0 md:ml-16 md:w-1/2 w-full flex justify-center">
          <div className="w-3/4 sm:w-2/3 md:w-full h-auto rounded-lg shadow-lg bg-gray-100 p-6 md:p-8 flex items-center justify-center">
            <span className="text-lg md:text-xl font-bold text-gray-700">
              
              Add a visual or illustration here
            </span>
          </div>
        </div> */}
      </div>

      {/* Hero Components Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-12 px-4 md:px-12">
        <HeroLatestJob />
        <HeroResult />
        <HeroAdmitCardList />
        <HeroSyllabus />
        <HeroAnswerKey />
        <HeroAdmission />
      </div>
    </section>
  );
}

export default HeroSection;
