import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function HeroSyllabus() {
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [visibleJobs, setVisibleJobs] = useState(7); // State to control how many jobs are visible
    const navigate = useNavigate(); // Initialize the useNavigate hook

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get('https://result.govtresult24.com/api/jobs');

                // Reverse or sort jobs to display latest ones first
                const reversedJobs = [...response.data].reverse(); // Reverse order
                // If there's a timestamp field like 'createdAt', use this:
                // const sortedJobs = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setJobs(reversedJobs);
                setFilteredJobs(reversedJobs);
            } catch (error) {
                console.error('Error fetching jobs:', error);
            }
        };
        fetchJobs();
    }, []);

    const handleViewMore = () => {
        navigate('/latest-job'); // Redirect to /latest-job page
    };

    return (
        <div className="container mx-auto p-4 pt-6 mt-10 bg-white rounded-lg shadow-lg border border-gray-300">
            <h1 className="text-5xl font-extrabold mb-8 text-center text-blue-600 drop-shadow-md">Syllabus</h1>
            <hr className="w-full border-t-2 border-gray-300 mb-8" />

            <div className="grid grid-cols-1 gap-6 max-w-6xl w-full mx-auto">
                {filteredJobs.slice(0, visibleJobs).map((job, index) => (
                    <div key={index} className="bg-white p-2 rounded-lg shadow-lg border border-gray-300 hover:bg-gray-100">
                        <div className="flex flex-col">
                            <h2 className="text-lg font-bold text-blue-700">{job.jobTitle}</h2>
                            {/* Additional job details can be added here */}
                            <Link
                                to={`/job-notifications/${job.jobTitle}`} // Use job ID or title for unique route
                                state={{ jobTitle: job.jobTitle }} // Pass job title or ID in state
                            >
                                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                                    Apply Now
                                </button>
                            </Link>
                        </div>
                    </div>
                ))}

                {visibleJobs < filteredJobs.length && ( // Show the "View More" button if there are more jobs to load
                    <div className="w-full text-end mt-6">
                        <button
                            onClick={handleViewMore}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                        >
                            View More
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default HeroSyllabus;
