import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
  HomeIcon,
  UserIcon,
  ChartBarIcon,
  DocumentIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  BookOpenIcon,
  AcademicCapIcon,
} from '@heroicons/react/24/outline';

function Navbar() {
  const [showMoreIcons, setShowMoreIcons] = useState(false);

  return (
    <div>
      {/* Top Navbar for larger screens */}
      <nav className="hidden md:flex justify-between items-center p-6 bg-white sticky top-0">
        <div className="text-2xl font-bold text-blue-700">
          <Link to="/">Govtresult24</Link>
        </div>
        <ul className="flex space-x-8 text-lg">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/latest-job"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Latest Job
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/result"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Result
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/AdmitCard-List"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Admit
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Answer"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Answer
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Syllabus"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Syllabus
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Admission"
              className={({ isActive }) =>
                isActive
                  ? 'text-blue-500 font-semibold'
                  : 'text-gray-700 hover:text-blue-500'
              }
            >
              Admission
            </NavLink>
          </li>
        </ul>
      </nav>

      {/* Hamburger Menu for mobile screens */}
      <div className="md:hidden flex justify-between items-center p-4 bg-white">
        <div className="text-2xl font-bold text-blue-700">GovtResult24</div>
        <button>Contact Us</button>
      </div>

      {/* Footer Navbar for mobile screens */}
      <nav className="md:hidden fixed bottom-0 left-0 right-0 bg-black text-gray-400 grid grid-cols-5 gap-2 p-3 z-10">
        <div className="flex flex-col items-center">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500'
                : 'text-gray-400 hover:text-blue-500'
            }
          >
            <HomeIcon className="h-6 w-6" />
            <span className="text-xs">Home</span>
          </NavLink>
        </div>
        <div className="flex flex-col items-center">
          <NavLink
            to="/latest-job"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500'
                : 'text-gray-400 hover:text-blue-500'
            }
          >
            <UserIcon className="h-6 w-6" />
            <span className="text-xs">Job</span>
          </NavLink>
        </div>
        <div className="flex flex-col items-center">
          <NavLink
            to="/result"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500'
                : 'text-gray-400 hover:text-blue-500'
            }
          >
            <ChartBarIcon className="h-6 w-6" />
            <span className="text-xs">Result</span>
          </NavLink>
        </div>
        <div className="flex flex-col items-center">
          <NavLink
            to="/AdmitCard-List"
            className={({ isActive }) =>
              isActive
                ? 'text-blue-500'
                : 'text-gray-400 hover:text-blue-500'
            }
          >
            <DocumentIcon className="h-6 w-6" />
            <span className="text-xs">Admit</span>
          </NavLink>
        </div>
        <div
          onClick={() => setShowMoreIcons(!showMoreIcons)}
          className="flex flex-col items-center cursor-pointer"
        >
          {showMoreIcons ? (
            <ChevronUpIcon className="h-6 w-6 text-gray-400" />
          ) : (
            <ChevronDownIcon className="h-6 w-6 text-gray-400" />
          )}
          <span className="text-xs">{showMoreIcons ? 'Hide' : 'More'}</span>
        </div>

        {/* Additional Icons */}
        {showMoreIcons && (
          <div className="col-span-5 grid grid-cols-5 gap-2 mt-2">
            <div className="flex flex-col items-center">
              <NavLink
                to="/Answer"
                className={({ isActive }) =>
                  isActive
                    ? 'text-blue-500'
                    : 'text-gray-400 hover:text-blue-500'
                }
              >
                <BookOpenIcon className="h-6 w-6" />
                <span className="text-xs">Answer</span>
              </NavLink>
            </div>
            <div className="flex flex-col items-center">
              <NavLink
                to="/syllabus"
                className={({ isActive }) =>
                  isActive
                    ? 'text-blue-500'
                    : 'text-gray-400 hover:text-blue-500'
                }
              >
                <DocumentIcon className="h-6 w-6" />
                <span className="text-xs">Syllabus</span>
              </NavLink>
            </div>
            <div className="flex flex-col items-center">
              <NavLink
                to="/Admission"
                className={({ isActive }) =>
                  isActive
                    ? 'text-blue-500'
                    : 'text-gray-400 hover:text-blue-500'
                }
              >
                <AcademicCapIcon className="h-6 w-6" />
                <span className="text-xs">Admission</span>
              </NavLink>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Navbar;
