import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroAdmitCardList = () => {
    const [admitCards, setAdmitCards] = useState([]);
    const [visibleCards, setVisibleCards] = useState(7); // Control initial number of admit cards shown
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchAdmitCards = async () => {
            try {
                const response = await fetch('https://result.govtresult24.com/api/admitcards');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                // Reverse or sort data to display latest admit cards first
                const reversedAdmitCards = [...data].reverse(); // Reverse order
                // If there's a timestamp field like 'createdAt', use this:
                // const sortedAdmitCards = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

                setAdmitCards(reversedAdmitCards);
            } catch (error) {
                console.error('Error fetching admit cards:', error);
            }
        };

        fetchAdmitCards();
    }, []);

    const handleRedirect = (applyLink) => {
        window.open(applyLink, '_blank'); // Open the link in a new tab
    };

    const handleViewMore = () => {
        navigate('/AdmitCard-List'); // Navigate to the /AdmitCard-List page
    };

    return (
        <div className="container mx-auto p-4 pt-6 mt-10 bg-white rounded-lg shadow-lg border border-gray-300">
            <h1 className="text-5xl font-extrabold mb-8 text-center text-blue-600 drop-shadow-md">Admit Cards</h1>
            <hr className="w-full border-t-2 border-gray-300 mb-8" />
            <div className="grid grid-cols-1 gap-4 w-full mx-auto">
                {admitCards.slice(0, visibleCards).map((card, index) => (
                    <div key={index} className="bg-white p-2 rounded-lg shadow-lg border border-gray-300 hover:bg-gray-100">
                        <h2 className="text-lg font-bold text-blue-700">{card.jobTitle}</h2>
                        <button
                            onClick={() => handleRedirect(card.applyLink)}
                            className="mt-4 bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-l text-white font-bold py-2 px-4 rounded transition duration-300 shadow-md hover:shadow-lg"
                        >
                            Get Admit Card                        </button>
                    </div>
                ))}

                {visibleCards < admitCards.length && ( // Show "View More" if there are more admit cards
                    <div className="w-full text-end mt-6">
                        <button
                            onClick={handleViewMore}
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                        >
                            View More
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeroAdmitCardList;
