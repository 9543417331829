import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import IPPBRecruitment from "./IPPBRecruitment";

// Format date function
const formatDate = (date) => {
  const d = new Date(date);
  let day = d.getDate();
  let month = d.getMonth() + 1; // Months are 0-indexed
  const year = d.getFullYear();

  // Add leading zeros if day or month is a single digit
  if (day < 10) day = "0" + day;
  if (month < 10) month = "0" + month;

  return `${day}-${month}-${year}`;
};

const JobNotificationsPage = () => {
  const { jobTitle } = useParams(); // Get the jobTitle from the URL params
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [items, setItems] = useState([]);

  // Fetch data when jobTitle changes
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching data
      try {
        await fetchNotifications();
        await fetchTableData(jobTitle); // Fetch table data specific to the jobTitle
        await fetchItemsData(jobTitle); // Fetch items data specific to the jobTitle
      } catch (err) {
        setError("There was an error fetching data");
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false); // Set loading to false after data is fetched or an error occurs
      }
    };

    fetchData();
  }, [jobTitle]);

  // Fetch job notifications
  const fetchNotifications = async () => {
    try {
      const response = await axios.get(
        `https://result.govtresult24.com/api/jobs/notifications/${jobTitle}`
      );
      if (response.data && response.data.length > 0) {
        // Assuming dynamicInputs are part of the notification data
        const formattedNotifications = response.data.map((notification) => ({
          ...notification,
          dynamicInputs: notification.dynamicInputs || [], // Add dynamic inputs here
        }));
        setNotifications(formattedNotifications);
      } else {
        setError("No notifications found for this job title.");
      }
    } catch (err) {
      setError("Error fetching job notifications");
      console.error(err);
    }
  };

  // Fetch table data based on the jobTitle
  const fetchTableData = async (jobTitle) => {
    try {
      const response = await axios.get(
        `https://result.govtresult24.com/api/tables/${jobTitle}` // Ensure this is the correct API route
      );
      console.log("Table Data Response:", response.data);

      // Filter table data to only show the current job's tables
      const filteredTableData = response.data.filter(
        (table) => table.jobTitle === jobTitle
      );
      setTableData(filteredTableData); // Set filtered table data
    } catch (err) {
      console.error("Error fetching table data:", err);
      // setError("Error fetching table data");
    }
  };

  // Fetch items data based on the jobTitle
  const fetchItemsData = async (jobTitle) => {
    try {
      const response = await axios.get(
        `https://result.govtresult24.com/api/items/${jobTitle}` // Ensure this is the correct API route
      );
      console.log("Items Data Response:", response.data);

      // Filter items data to only show the current job's items
      const filteredItemsData = response.data.filter(
        (item) => item.jobTitle === jobTitle
      );
      setItems(filteredItemsData); // Set filtered items data
    } catch (err) {
      console.error("Error fetching items data:", err);
      // setError("Error fetching items data");
    }
  };

  if (loading) {
    return (
      <div className="text-center text-gray-600 text-xl md:text-2xl">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center text-gray-600 text-xl md:text-2xl">
        {error}
      </div>
    );
  }

  return (
    <div className="mx-auto pt-10 mt-10 bg-gradient-to-br from-gray-100 to-blue-50 p-6 md:p-8 rounded-3xl shadow-2xl max-w-7xl border border-gray-300">
      <h1 className="text-center text-4xl md:text-5xl font-extrabold text-blue-800 mb-10 tracking-wider">
        {jobTitle}
      </h1>

      {/* Job Notifications Section */}
      {notifications.length === 0 ? (
        <p className="text-center text-gray-600 text-xl md:text-2xl">
          No notifications available
        </p>
      ) : (
        notifications.map((notification, index) => (
          <div key={index} className="mb-10">
            {/* Job Details Section */}
            <div>
              <h2 className="text-3xl font-bold text-blue-800 mb-4">
                Job Details
              </h2>
              <div className="border-l-8 border-blue-600 p-4 bg-white rounded-lg shadow-lg mb-4">
                <h3 className="font-bold text-xl text-blue-700 mb-2">
                  📌 Post Name:
                </h3>
                <p className="text-lg text-gray-700">
                  {notification.nameOfPost}
                </p>
              </div>
              <div className="border-l-8 border-green-500 p-4 bg-white rounded-lg shadow-lg mb-4">
                <h3 className="font-bold text-xl text-green-600 mb-2">
                  📅 Post Date:
                </h3>
                <p className="text-lg text-gray-700">{notification.postDate}</p>
              </div>
              <div className="border-l-8 border-yellow-500 p-4 bg-white rounded-lg shadow-lg">
                <h3 className="font-bold text-xl text-yellow-700 mb-2">
                  ℹ️ Short Information:
                </h3>
                <p className="text-lg text-gray-700">
                  {notification.shortInfo}
                </p>
              </div>
            </div>

            {/* NICL Assistant Notifications Section */}
            {/* <div className="mt-8">
              <h2 className="text-3xl font-bold text-purple-800 mb-4">
                Notifications
              </h2>
              <div className="border-l-8 border-purple-500 p-4 bg-white rounded-lg shadow-lg mb-4">
                <h3 className="font-bold text-xl text-purple-600 mb-2">
                  📝 Requirement Title:
                </h3>
                <p className="text-lg text-gray-700">
                  {notification.requirementTitle}
                </p>
              </div>
              <div className="border-l-8 border-orange-500 p-4 bg-white rounded-lg shadow-lg mb-4">
                <h3 className="font-bold text-xl text-orange-600 mb-2">
                  🎓 Exam Title:
                </h3>
                <p className="text-lg text-gray-700">
                  {notification.examTitle}
                </p>
              </div>
              <div className="border-l-8 border-teal-500 p-4 bg-white rounded-lg shadow-lg">
                <h3 className="font-bold text-xl text-teal-600 mb-2">
                  🔗 Website Link:
                </h3>
                <a
                  className="text-blue-500 break-words"
                  href={notification.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {notification.websiteLink}
                </a>
              </div>
            </div> */}

            {/* Important Dates Section */}
            <div className="mt-8">
              <h2 className="text-3xl font-bold text-pink-800 mb-4">
                Important Dates
              </h2>
              <div className="border-l-8 border-pink-500 p-4 bg-white rounded-lg shadow-lg mb-4">
                <h3 className="font-bold text-xl text-pink-600 mb-2">
                  📅 Application Dates:
                </h3>
                <p className="text-lg text-gray-700">
                  Application Begin: {notification.applicationBegin}
                </p>
                <p className="text-lg text-gray-700">
                  Last Date for Apply Online: {notification.lastDateApply}
                </p>
                <p className="text-lg text-gray-700">
                  Last Date to Pay Exam Fee: {notification.lastDatePayExamFee}
                </p>
                <p className="text-lg text-gray-700">
                  Exam Date: {notification.examDate}
                </p>
                <p className="text-lg text-gray-700">
                  Admit Card Available: {notification.admitCardAvailable}
                </p>
                <div>
                  {/* <h3>Job Notifications</h3> */}
                  <ul>
                    {notifications.map((notification) => (
                      <li key={notification._id}>
                        {/* <p>{notification.nameOfPost}</p> */}
                        {/* Render other fields */}
                        <div>
                          {/* <strong>Additional Dates:</strong> */}
                          <ul>
                            {notification.additionalDates &&
                            notification.additionalDates.length > 0 ? (
                              notification.additionalDates.map(
                                (date, index) => (
                                  <li key={index}>{date.dateValue}</li> // Render each date value
                                )
                              )
                            ) : (
                              <li>No additional dates available</li>
                            )}
                          </ul>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Application Fee Section */}
            <div className="mt-8">
              <h2 className="text-3xl font-bold text-red-800 mb-4">
                Application Fee
              </h2>
              <div className="border-l-8 border-red-500 p-4 bg-white rounded-lg shadow-lg">
                <h3 className="font-bold text-xl text-red-600 mb-2">
                  💲 Fee Details:
                </h3>
                <p className="text-lg text-gray-700">
                  General Fee: {notification.generalFee}
                </p>
                <p className="text-lg text-gray-700">
                  SC/ST/PH Fee: {notification.scStPhFee}
                </p>
                <p className="text-lg text-gray-700">
                  {" "}
                  {notification.additionalInfo}
                </p>
                {notifications.map((notification) => (
                  <div key={notification._id}>
                    {/* <h3>{notification.nameOfPost}</h3> */}
                    <ul>
                      {notification.dynamicInputs?.map((input, idx) => (
                        <li key={idx}>{input}</li> // Render each dynamic input
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>

            {/* Insurance Notifications Section */}
            <div className="mt-8">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                {" "}
                {notification.Title}
              </h2>
              <div className="border-l-8 border-gray-500 p-4 bg-white rounded-lg shadow-lg">
                <h3 className="font-bold text-xl text-gray-600 mb-2">
                  📝 Details:
                </h3>
                <p className="text-lg text-gray-700">
                  Minimum Age: {notification.StartDate}
                </p>
                <p className="text-lg text-gray-700">
                  Maximum Age: {notification.EndDate}
                </p>
                <p className="text-lg text-gray-700">
                  {notification.AgeRelaxation}
                </p>
                <p className="text-lg text-gray-700">{notification.details}</p>

                {notifications.map((notification) => (
                  <div key={notification._id}>
                    {/* <h3>{notification.nameOfPost}</h3> */}
                    <ul>
                      {notification.postRecruitmentInputs?.map((input, idx) => (
                        <li key={idx}>{input}</li> // Render each dynamic input
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))
      )}

      {/* Table Section */}
     {/* Table Section */}
     <div className="overflow-x-auto mb-8 shadow-lg rounded-lg bg-white border-l-8 border-green-500">
  {tableData.length === 0 ? null : ( // If no table data, render nothing
    <table className="min-w-full table-auto">
      <thead>
        <tr className="bg-gray-200">
          <th
            colSpan="100%"
            className="py-4 px-6 text-center text-3xl font-bold text-blue-800 border border-gray-300"
          >
            <span>{jobTitle}</span>
          </th>
        </tr>
        
      </thead>
      <tbody>
        {tableData.map((table, tableIndex) => (
          <React.Fragment key={tableIndex}>
            {table.data.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                className="border-t hover:bg-indigo-50 transition-all duration-300"
              >
                {row.map((cell, colIndex) => (
                  <td
                    key={colIndex}
                    className="px-4 py-3 text-sm text-gray-700 bg-white border-t border-l border-gray-300 min-w-[100px] break-words"
                  >
                    {/* Check if the cell contains the specific text (link case) */}
                    {cell === "UPSSSC Junior Assistant Eligibility" ? (
                      <a
                        href="https://example.com" // Replace this link with the actual link
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {cell}
                      </a>
                    ) : (
                      <span>{cell}</span> // Normal content
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  )}
</div>

      {/* Items List Section */}
      <div className=" bg-gray-100">
        <div className="overflow-x-auto border-l-8 border-red-500 p-4 bg-white rounded-lg shadow-lg">
          <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-lg">
            {/* <thead className="bg-gray-200 p-2">
        <tr>
          <th
            colSpan={2}
            className="text-3xl font-bold text-blue-800 mb-6 p-4 text-center"
          >
            Some UseFull Link
          </th>
        </tr>
      </thead> */}
            <tbody>
              {items.length === 0 ? (
                <tr>
                  <td colSpan="2" className="text-center py-4">
                    Loading...
                  </td>
                </tr>
              ) : (
                items.map((item) => (
                  <React.Fragment key={item._id}>
                    {/* Render Title 1 and Link 1 */}
                    <tr className="bg-gray-200 p-2">
                      <th
                        colSpan={2}
                        className="text-3xl font-bold text-blue-800 mb-6 p-4 text-center"
                      >
                        Some Useful Links
                      </th>
                    </tr>
                    {item.dynamicFields &&
                      item.dynamicFields.map((field, index) => (
                        <tr
                          key={field._id}
                          className="border-b border-gray-300"
                        >
                          <td className="py-4 px-6 text-gray-700 border-r border-gray-300">
                            <div className="border-l-8 border-blue-500 p-4 bg-white rounded-lg shadow-lg">
                              <p className="font-bold text-xl text-blue-600 mb-2">
                                📌 {field.title}
                              </p>
                            </div>
                          </td>
                          <td className="py-4 px-6 border-gray-300">
                            <div className="border-l-8 border-blue-500 p-4 bg-white rounded-lg shadow-lg">
                              <a
                                href={field.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <button className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition duration-300">
                                  Click Here
                                </button>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))}

                    {/* Render PDF Title and Download Link (if available) */}
                    {item.pdfTitle && (
                      <tr className="border-b border-gray-300">
                        <td className="py-4 px-6 text-gray-700 border-r border-gray-300">
                          <div className="border-l-8 border-yellow-500 p-4 bg-white rounded-lg shadow-lg">
                            <p className="font-bold text-xl text-yellow-600 mb-2">
                              📄 {item.pdfTitle}
                            </p>
                          </div>
                        </td>
                        <td className="py-4 px-6 border-gray-300">
                          <div className="border-l-8 border-yellow-500 p-4 bg-white rounded-lg shadow-lg">
                            <a
                              href={`https://result.govtresult24.com/${item.pdf}`}
                              download={item.pdfTitle}
                            >
                              <button className="px-4 py-2 bg-yellow-500 text-white rounded-lg hover:bg-yellow-600 transition duration-300">
                                Download PDF
                              </button>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <IPPBRecruitment />
    </div>
  );
};

export default JobNotificationsPage;
