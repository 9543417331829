import React from "react";

const IPPBRecruitment = () => {
  return (
    <div className="p-4 space-y-4">
      <div className="mx-auto my-10 bg-gradient-to-br from-gray-100 to-blue-50 p-6 md:p-8 rounded-lg shadow-2xl  border border-gray-300">
        <h2 className="text-center font-bold text-2xl md:text-3xl text-blue-800 mb-10 tracking-wider">
          How to Fill Online Form
        </h2>
        <div className="space-y-4">
          <div className="border-l-8 border-blue-600 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Learn how to fill form for Watch our step-by-step guide on our YouTube
              channel to make the process easier.
              {/* <strong>Form Submission Deadline: 05/11/2024</strong> */}
              <br />
              <a
                href="https://www.youtube.com/c/aellexd"
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:underline"
              >
                Watch the tutorial here
              </a>
            </p>
          </div>

          <div className="border-l-8 border-green-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Candidates should read the notification before applying for the
              Recruitment Application Form in  I Latest Recruitment
              Online Form.
            </p>
          </div>

          <div className="border-l-8 border-yellow-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Kindly check and collect all documents - Eligibility, ID Proof,
              Address Details, Basic Details.
            </p>
          </div>

          <div className="border-l-8 border-purple-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Kindly have scanned documents related to the Recruitment Form -
              Photo, Signature, ID Proof, etc.
            </p>
          </div>

          <div className="border-l-8 border-orange-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Before submitting the application form, make sure to check the
              preview and all columns carefully.
            </p>
          </div>

          <div className="border-l-8 border-red-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              If a candidate is required to pay the application fee, it must be
              submitted. If you do not have the required application fees, your
              form will not be completed.
            </p>
          </div>

          <div className="border-l-8 border-blue-500 p-4 md:p-5 bg-white rounded-lg shadow-lg hover:shadow-xl">
            <p className="mt-2 text-gray-800">
              Take a printout of the final submitted form.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IPPBRecruitment;
