import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function Syllabus() {
    const [searchTerm, setSearchTerm] = useState('');
    const [jobs, setJobs] = useState([]);
    const [filteredJobs, setFilteredJobs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const response = await axios.get('https://result.govtresult24.com/api/jobs');

                // Reverse or sort the jobs to display the latest ones first
                const reversedJobs = [...response.data].reverse();

                setJobs(reversedJobs);
                setFilteredJobs(reversedJobs);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching jobs:', error);
                setLoading(false);
            }
        };
        fetchJobs();
    }, []);

    const handleSearch = () => {
        const filtered = jobs.filter((job) =>
            job.jobTitle.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredJobs(filtered);
    };

    return (
        <div className="container mx-auto p-4 pt-6 mt-10 mb-16">
            <h1 className="text-5xl font-extrabold mb-8 text-center text-blue-600 drop-shadow-md">Syllabus</h1>
            <hr className="w-full border-t-2 border-gray-300 mb-8" />

            {/* Search Bar */}
            <div className="mb-6 flex justify-center">
                <div className="flex items-stretch">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Search jobs..."
                        className="border border-gray-300 rounded-l-lg py-3 px-4 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        style={{ height: '50px', width: '300px' }}
                    />
                    <button
                        onClick={handleSearch}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-r-lg transition duration-300"
                        style={{ height: '50px' }}
                    >
                        Search
                    </button>
                </div>
            </div>

            {/* Loading Spinner or Job Listings */}
            {loading ? (
                <div className="flex justify-center items-center space-x-2">
                    <div className="w-8 h-8 border-4 border-blue-600 border-dashed rounded-full animate-spin"></div>
                    <p className="text-lg text-blue-700">Loading job listings...</p>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 max-w-6xl w-full mx-auto">
                    {filteredJobs.length > 0 ? (
                        filteredJobs.map((job, index) => (
                            <div
                                key={index}
                                className="bg-white p-6 rounded-lg shadow-lg border border-gray-300 transition-transform transform hover:scale-105"
                                style={{ minHeight: '180px' }}
                            >
                                <div className="flex flex-col gap-3">
                                    <h2 className="text-lg font-bold text-blue-700">{job.jobTitle}</h2>
                                    <Link
                                        to={`/job-notifications/${job.jobTitle}`}
                                        state={{ jobTitle: job.jobTitle }}
                                    >
                                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300">
                                            Apply Now
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center text-gray-600 text-xl md:text-2xl">No jobs found.</p>
                    )}
                </div>
            )}
        </div>
    );
}

export default Syllabus;
