import React, { useState, useEffect } from "react";
import axios from "axios";

const ResultAdmin = () => {
  const [listingTitle, setListingTitle] = useState("");
  const [applyLink, setApplyLink] = useState("");
  const [pdfFile, setPdfFile] = useState(null);  // State to store the PDF file
  const [jobListings, setJobListings] = useState([]);
  const [editIndex, setEditIndex] = useState(-1); // Track which listing is being edited
  const [existingPdf, setExistingPdf] = useState(""); // Store existing PDF URL for editing

  useEffect(() => {
    fetchJobListings();
  }, []);

  const fetchJobListings = async () => {
    try {
      const response = await axios.get("https://result.govtresult24.com/api/jobListings");
      setJobListings(response.data);
    } catch (err) {
      console.error("Error fetching job listings", err);
    }
  };

  const isValidUrl = (string) => {
    try {
      new URL(string);  // Try creating a URL object, it will throw an error if the URL is invalid
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleFileChange = (e) => {
    setPdfFile(e.target.files[0]);  // Store the selected PDF file
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the applyLink before submitting (only if it's not empty)
    if (applyLink && !isValidUrl(applyLink)) {
      alert("Please enter a valid URL for the Apply Link.");
      return;
    }

    const formData = new FormData();
    formData.append("listingTitle", listingTitle);
    
    // Send the applyLink as an empty string if not provided
    formData.append("applyLink", applyLink || ""); 

    // Append the new PDF file if selected, otherwise keep the existing one
    if (pdfFile) {
      formData.append("pdfFile", pdfFile);
    } else if (existingPdf) {
      formData.append("pdfFile", existingPdf);  // Keep the existing PDF if no new file is selected
    }

    try {
      if (editIndex === -1) {
        await axios.post("https://result.govtresult24.com/api/jobListings", formData, {
          headers: {
            "Content-Type": "multipart/form-data",  // Important header for file uploads
          },
        });
      } else {
        await axios.put(
          `https://result.govtresult24.com/api/jobListings/${jobListings[editIndex]._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",  // Important header for file uploads
            },
          }
        );
        setEditIndex(-1); // Reset edit mode
        setExistingPdf(""); // Clear the existing PDF after update
      }

      setListingTitle("");
      setApplyLink("");  // Reset the applyLink field
      setPdfFile(null);  // Reset the file input after submitting
      fetchJobListings(); // Fetch the updated list of job listings
    } catch (err) {
      console.error("Error saving job listing", err);
    }
  };

  const handleDelete = (id) => {
    axios
      .delete(`https://result.govtresult24.com/api/jobListings/${id}`)
      .then((response) => {
        fetchJobListings();  // Refresh the listings after deletion
      })
      .catch((error) => {
        console.error("Error deleting job listing", error);
      });
  };

  const handleEdit = (index) => {
    const listing = jobListings[index];
    setListingTitle(listing.listingTitle);
    setApplyLink(listing.applyLink);
    setExistingPdf(listing.pdfFile);  // Store the current PDF URL for editing
    setEditIndex(index);
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form onSubmit={handleSubmit} className="mb-6" encType="multipart/form-data">
        <div className="mb-4">
          <label className="block text-gray-700">Listing Title:</label>
          <input
            type="text"
            value={listingTitle}
            onChange={(e) => setListingTitle(e.target.value)}
            className="border rounded p-2 w-full"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Apply Link:</label>
          <input
            type="text"
            value={applyLink}
            onChange={(e) => setApplyLink(e.target.value)}
            className="border rounded p-2 w-full"
          
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700">Upload PDF:</label>
          <input
            type="file"
            accept=".pdf"
            onChange={handleFileChange}
            className="border rounded p-2 w-full"
          />
          {existingPdf && !pdfFile && (
            <p className="mt-2 text-gray-600">Current PDF: <a href={existingPdf} target="_blank" rel="noopener noreferrer" className="text-blue-500">View PDF</a></p>
          )}
        </div>
        <button type="submit" className="bg-blue-500 text-white rounded p-2">
          {editIndex === -1 ? "Add Listing" : "Update Listing"}
        </button>
      </form>
      <div className="bg-white p-4 rounded-lg shadow-lg">
        <h2 className="font-bold">Added Listings</h2>
        {jobListings.map((listing, index) => (
          <div
            key={listing._id}
            className="mb-2 border p-3 bg-gray-100 rounded flex justify-between"
          >
            <div>
              <h3 className="font-bold">{listing.listingTitle}</h3>
              <a
                href={listing.applyLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500"
              >
                Apply Now
              </a>
              {listing.pdfFile && (
                <a
                  href={listing.pdfFile}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 ml-2"
                >
                  View PDF
                </a>
              )}
            </div>
            <div className="flex flex-col justify-between">
              <button
                onClick={() => handleEdit(index)}
                className="bg-yellow-500 text-white rounded mt-1 py-1 px-2"
              >
                Edit
              </button>
              <button
                onClick={() => handleDelete(listing._id)}
                className="bg-red-500 text-white rounded mt-1 py-1 px-2"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultAdmin;
