import React, { useState, useEffect } from "react";

const Result = () => {
  const [admitCards, setAdmitCards] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [isLoading, setIsLoading] = useState(true); // State for loading

  // Fetch job listings from the backend when the component mounts
  useEffect(() => {
    const fetchJobListings = async () => {
      try {
        const response = await fetch("https://result.govtresult24.com/api/joblistings"); // Replace with your backend URL
        const data = await response.json();

        // Reverse the order of listings or sort by createdAt field if available
        const sortedData = [...data].reverse(); // Reverse the order to show the most recent first
        // If the backend has a createdAt field, you can sort using:
        // const sortedData = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setAdmitCards(sortedData); // Update state with sorted data
      } catch (error) {
        console.error("Error fetching job listings:", error);
      } finally {
        setIsLoading(false); // Set loading to false after data has been fetched
      }
    };

    fetchJobListings();
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  const handleRedirect = (applyLink) => {
    window.open(applyLink, "_blank"); // Open the link in a new tab
  };

  const handleDownloadPDF = (pdfUrl) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = pdfUrl.split("/").pop(); // Automatically download the file with its name
    link.click(); // Simulate a click to trigger the download
  };

  const filteredAdmitCards = admitCards.filter((card) =>
    card.listingTitle.toLowerCase().includes(searchQuery.toLowerCase())
  ); // Filter admit cards based on the search query

  return (
    <div className="max-w-6xl mb-16 mx-auto mt-10 p-5 border rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Result</h1>
      <div className="mb-6 flex justify-center">
        <div className="flex items-stretch">
          <input
            type="text"
            placeholder="Search by name..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="border border-gray-300 rounded-l-lg py-3 px-4 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-r-lg transition duration-300"
            style={{ height: "50px" }}
          >
            Search
          </button>
        </div>
      </div>

      {/* Show loading spinner when data is being fetched */}
      {isLoading ? (
        <div className="flex justify-center items-center space-x-2">
          <div className="w-8 h-8 border-4 border-blue-600 border-dashed rounded-full animate-spin"></div>
          <p className="text-lg text-blue-700">Loading job listings...</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredAdmitCards.length > 0 ? (
            filteredAdmitCards.map((card, index) => (
              <div key={index} className="p-4 border border-gray-300 rounded">
                <h2 className="text-lg font-semibold">{card.listingTitle}</h2>
                <button
                  onClick={() => handleRedirect(card.applyLink)} // Redirect to the apply link
                  className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                >
                  Check Result
                </button>
                {card.pdfFile && (
                  <button
                    onClick={() => handleDownloadPDF(card.pdfFile)} // Download PDF
                    className="mt-2 ml-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                  >
                    Download PDF
                  </button>
                )}
              </div>
            ))
          ) : (
            <p>No job listings found.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Result;
