import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdmitcardAdmin = () => {
  const [jobTitle, setJobTitle] = useState('');
  const [applyLink, setApplyLink] = useState('');
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [admitCards, setAdmitCards] = useState([]);

  useEffect(() => {
    fetchAdmitCards();
  }, []);

  const fetchAdmitCards = async () => {
    try {
      const response = await axios.get('https://result.govtresult24.com/api/admitcards'); // Update URL
      setAdmitCards(Array.isArray(response.data) ? response.data : []);
    } catch (err) {
      console.error('Error fetching admit cards:', err);
    } 
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (selectedCardId) {
        await axios.put(`https://result.govtresult24.com/api/admitcards/${selectedCardId}`, { jobTitle, applyLink }); // Update URL
      } else {
        await axios.post('https://result.govtresult24.com/api/admitcards', { jobTitle, applyLink }); // Update URL
      }
      fetchAdmitCards();
      resetForm();
    } catch (err) {
      console.error('Error submitting form:', err);
    }
  };

  const handleEdit = (card) => {
    setJobTitle(card.jobTitle);
    setApplyLink(card.applyLink);
    setSelectedCardId(card._id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://result.govtresult24.com/api/admitcards/${id}`); // Update URL
      fetchAdmitCards();
    } catch (err) {
      console.error('Error deleting admit card:', err);
    }
  };

  const resetForm = () => {
    setJobTitle('');
    setApplyLink('');
    setSelectedCardId(null);
  };

  return (
    <div className="max-w-2xl mx-auto mt-10 p-5 border rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="jobTitle">
            Job Title:
          </label>
          <input
            type="text"
            id="jobTitle"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700" htmlFor="applyLink">
            Apply Link:
          </label>
          <input
            type="url"
            id="applyLink"
            value={applyLink}
            onChange={(e) => setApplyLink(e.target.value)}
            required
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
        <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 rounded">
          {selectedCardId ? 'Update' : 'Submit'}
        </button>
      </form>
      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Admit Cards</h2>
        <ul>
          {admitCards.map((card) => (
            <li key={card._id} className="mb-4 p-4 border rounded-md shadow-sm">
              <h3 className="text-lg font-semibold">{card.jobTitle}</h3>
              <p>{card.applyLink}</p>
              <button onClick={() => handleEdit(card)} className="mr-2 bg-yellow-500 hover:bg-yellow-600 text-white py-1 px-2 rounded">
                Edit
              </button>
              <button onClick={() => handleDelete(card._id)} className="bg-red-500 hover:bg-red-600 text-white py-1 px-2 rounded">
                Delete
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AdmitcardAdmin;
