import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import IPPBRecruitment from '../IPPBRecruitment';

function ShowLatestJob() {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation(); // Get the location object
    const job = location.state.job; // Get job data from the location state

    useEffect(() => {
        const fetchPosts = async () => {
            if (!job) return; // Exit if no job is selected
            try {
                const response = await fetch(`https://result.govtresult24.com/api/jobs/${job._id}/posts`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error('Error fetching posts:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchPosts();
    }, [job]); // Fetch posts when job changes

    if (loading) {
        return <p className="text-center text-gray-600 text-xl md:text-2xl">Loading...</p>;
    }

    return (
        <>
            <div className="mx-auto pt-10 mt-10 bg-gradient-to-br from-gray-100 to-blue-50 p-6 md:p-8 rounded-3xl shadow-2xl max-w-5xl border border-gray-300">
                <h1 className="text-center text-4xl md:text-5xl font-extrabold text-blue-800 mb-10 tracking-wider">
                    Latest Job Postings
                </h1>

                {/* <div className="mb-6">
                    <h2 className="text-2xl font-bold text-blue-700">{job.jobTitle}</h2>
                    <h3 className="text-xl text-gray-600">{job.salaryRange}</h3>
                </div> */}

                {posts.length > 0 ? (
                    posts.map((post) => (
                        <div key={post._id} className="mb-8">
                            <div className="border-l-8 border-blue-600 p-4 md:p-5 bg-white rounded-lg shadow-lg">
                                <h2 className="font-bold text-xl md:text-2xl text-blue-700 mb-2">📌 Name of Post:</h2>
                                <p className="text-base md:text-lg text-gray-700">{post.name}</p>
                            </div>
                            <div className="border-l-8 border-green-500 p-4 md:p-5 bg-white rounded-lg shadow-lg mt-4">
                                <h2 className="font-bold text-xl md:text-2xl text-green-600 mb-2">📅 Post Date / Update:</h2>
                                <p className="text-base md:text-lg text-gray-700">{new Date(post.postDate).toLocaleString()}</p>
                            </div>
                            <div className="border-l-8 border-yellow-500 p-4 md:p-5 bg-white rounded-lg shadow-lg mt-4">
                                <h2 className="font-bold text-xl md:text-2xl text-yellow-700 mb-2">ℹ️ Short Information:</h2>
                                <p className="text-base md:text-lg text-gray-700">{post.shortInfo}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-center text-gray-600 text-xl md:text-2xl">No posts available.</p>
                )}
            </div>

            <IPPBRecruitment />
        </>
    );
}

export default ShowLatestJob;
