import React, { useState } from "react";

function NDAAdminPanel() {
  const [ageData, setAgeData] = useState({
    title: "",
    startDate: "",
    endDate: "",
    ageRelaxation: "",
  });

  const handleChange = (e) => {
    setAgeData({ ...ageData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Placeholder for submission logic
    alert("Form submitted with data: " + JSON.stringify(ageData));
    resetForm();
  };

  const resetForm = () => { 
    setAgeData({
      title: "",
      startDate: "",
      endDate: "",
      ageRelaxation: "",
    });
  };

  return (
    <div className="border p-6 bg-white rounded-lg shadow-md max-w-4x2 mx-auto mt-10 space-y-4">
      <h2 className="text-2xl font-bold text-teal-600 mb-4 text-center">
        UPSC NDA I Notification 2024 Admin Panel
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Notification Title */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            Notification Title:
          </label>
          <input
            type="text"
            name="title"
            value={ageData.title}
            onChange={handleChange}
            placeholder="Enter Notification Title"
            className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400"
            required
          />
        </div>

        {/* Start Date */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            Age Start Date:
          </label>
          <input
            type="date"
            name="startDate"
            value={ageData.startDate}
            onChange={handleChange}
            className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400"
            required
          />
        </div>

        {/* End Date */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            Age End Date:
          </label>
          <input
            type="date"
            name="endDate"
            value={ageData.endDate}
            onChange={handleChange}
            className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400"
            required
          />
        </div>

        {/* Age Relaxation Info */}
        <div className="flex flex-col">
          <label className="font-semibold text-gray-700 mb-1">
            Age Relaxation:
          </label>
          <textarea
            name="ageRelaxation"
            value={ageData.ageRelaxation}
            onChange={handleChange}
            placeholder="Enter age relaxation details"
            className="border p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-teal-400"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-teal-500 hover:bg-teal-600 text-white font-bold p-3 rounded-md mt-4 transition duration-300"
        >
          Submit
        </button>
      </form>

      {/* Placeholder for Current Age Limit Details */}
      <div className="mt-8 border-t pt-4">
        <h3 className="text-xl font-semibold text-teal-600 mb-2">
          Current Age Limit Details (Placeholder)
        </h3>
        <p>No notifications available.</p>
      </div>
    </div>
  );
}

export default NDAAdminPanel;
