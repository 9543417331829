import React, { useState, useEffect } from "react";
import axios from "axios";

const DynamicTable = () => {
  const [tableData, setTableData] = useState([[""]]); // Initial 1x1 cell
  const [tableId, setTableId] = useState(null); // Track the ID of the table in the database
  const [jobs, setJobs] = useState([]); // State to hold job titles
  const [formData, setFormData] = useState({ selectedJobTitle: "" }); // State to hold selected job title
  const [savedTables, setSavedTables] = useState([]); // State to hold saved table data

  // Fetch job titles when the component mounts
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get("https://result.govtresult24.com/api/jobs");
        setJobs(response.data);
      } catch (err) {
        console.error("Error fetching job titles:", err.response || err);
      }
    };
 
    fetchJobs();
  }, []);

  // Fetch saved tables
  const fetchSavedTables = async () => {
    try {
      const response = await axios.get("https://result.govtresult24.com/api/tables");
      setSavedTables(response.data);
    } catch (err) {
      console.error("Error fetching saved tables:", err.response || err);
    }
  };

  useEffect(() => {
    fetchSavedTables();
  }, []); // Run on mount only once

  // Handle change for select dropdown
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Filter saved tables based on selected job title
  const filteredTables = savedTables.filter(
    (table) => table.jobTitle === formData.selectedJobTitle
  );

  // Add a new row with the same number of columns as the first row
  const handleAddRow = () => {
    const newRow = Array(tableData[0].length).fill("");
    setTableData([...tableData, newRow]);
  };

  // Add a new column to the last row
  const handleAddColumn = () => {
    const updatedTableData = tableData.map((row, index) =>
      index === tableData.length - 1 ? [...row, ""] : row
    );
    setTableData(updatedTableData);
  };

  // Update the value of a specific cell
  const handleCellChange = (e, rowIndex, colIndex) => {
    const updatedTableData = [...tableData];
    updatedTableData[rowIndex][colIndex] = e.target.value;
    setTableData(updatedTableData);
  };

  // Save the table data to the backend
  const handleSave = async () => {
    if (tableId) {
      try {
        await axios.put(`https://result.govtresult24.com/api/tables/${tableId}`, {
          jobTitle: formData.selectedJobTitle,
          data: tableData,
        });
        alert("Table data updated!");
        setTableData([[""]]); // Reset the table data to an empty state after saving
        setTableId(null); // Clear table ID for new data entry
        // Fetch updated saved tables
        fetchSavedTables();
      } catch (err) {
        console.error("Error saving table data:", err);
        alert("Error saving table data");
      }
    } else {
      try {
        const response = await axios.post("https://result.govtresult24.com/api/tables", {
          jobTitle: formData.selectedJobTitle,
          data: tableData,
        });
        setTableId(response.data._id); // Set the ID after saving for future updates
        alert("Table data saved!");
        setTableData([[""]]); // Reset the table data after saving
        setTableId(null); // Clear the table ID for new data entry
        // Fetch updated saved tables
        fetchSavedTables();
      } catch (err) {
        console.error("Error saving table data:", err);
        alert("Error saving table data");
      }
    }
  };

  // Delete a saved table
  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://result.govtresult24.com/api/tables/${id}`);
      alert("Table data deleted!");
      // Fetch updated saved tables
      fetchSavedTables();
    } catch (err) {
      console.error("Error deleting table:", err);
      alert("Error deleting table data");
    }
  };

  // Edit a saved table (populate the form with the data)
  const handleEdit = async (id) => {
    try {
      const response = await axios.get(
        `https://result.govtresult24.com/api/tables/${id}`
      );
      const { jobTitle, data } = response.data;
      setFormData({ selectedJobTitle: jobTitle });
      setTableData(data);
      setTableId(id); // Set table ID for future updates
    } catch (err) {
      console.error("Error fetching table data for editing:", err);
    }
  };

  return (
    <div className="p-8 space-y-8">
      <div className="flex flex-col space-y-4">
        <div className="flex items-center space-x-4">
          <select
            name="selectedJobTitle"
            value={formData.selectedJobTitle}
            onChange={handleChange}
            required
            className="p-2 border rounded shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Job Title</option>
            {jobs.map((job) => (
              <option key={job._id} value={job.jobTitle}>
                {job.jobTitle}
              </option>
            ))}
          </select>
        </div>

        {/* Table Actions */}
        <div className="flex space-x-4">
          <button
            className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition"
            onClick={handleAddRow}
          >
            Add Row
          </button>
          <button
            className="bg-green-500 text-white px-6 py-2 rounded-lg hover:bg-green-600 transition"
            onClick={handleAddColumn}
          >
            Add Column
          </button>
          <button
            className="bg-yellow-500 text-white px-6 py-2 rounded-lg hover:bg-yellow-600 transition"
            onClick={handleSave}
          >
            Save Table Data
          </button>
        </div>
      </div>

      {/* Display selected job title above the table */}
      {formData.selectedJobTitle && (
        <h2 className="text-xl font-bold">{formData.selectedJobTitle}</h2>
      )}

      {/* Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr>
              <th className="border px-4 py-2 bg-gray-100">Row/Col</th>
              {tableData[0].map((_, colIndex) => (
                <th key={colIndex} className="border px-4 py-2 bg-gray-100">
                  Column {colIndex + 1}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b">
                <td className="border p-2 bg-gray-50">Row {rowIndex + 1}</td>
                {row.map((cell, colIndex) => (
                  <td key={`${rowIndex}-${colIndex}`} className="border p-2">
                    <input
                      type="text"
                      value={cell}
                      onChange={(e) => handleCellChange(e, rowIndex, colIndex)}
                      className="w-full p-2 border rounded-md"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Display filtered saved tables */}
      <div className="mt-8">
        <h2 className="text-2xl font-bold">Saved Tables</h2>
        <div className="space-y-4">
          {filteredTables.map((table) => (
            <div key={table._id} className="border p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">{table.jobTitle}</h3>
              <table className="mt-4">
                <thead>
                  <tr>
                    <th className="border px-4 py-2 bg-gray-100">Row/Col</th>
                    {table.data[0].map((_, colIndex) => (
                      <th
                        key={colIndex}
                        className="border px-4 py-2 bg-gray-100"
                      >
                        Column {colIndex + 1}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {table.data.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      <td className="border px-4 py-2 bg-gray-50">
                        Row {rowIndex + 1}
                      </td>
                      {row.map((cell, colIndex) => (
                        <td
                          key={`${rowIndex}-${colIndex}`}
                          className="border px-4 py-2"
                        >
                          {cell}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="mt-4 flex space-x-4">
                <button
                  className="bg-yellow-500 text-white px-4 py-2 rounded-lg hover:bg-yellow-600"
                  onClick={() => handleEdit(table._id)}
                >
                  Edit
                </button>
                <button
                  className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
                  onClick={() => handleDelete(table._id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DynamicTable;
