import React, { useEffect, useState } from 'react';

const AdmitCardList = () => {
    const [admitCards, setAdmitCards] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoading, setIsLoading] = useState(true); // State for loading

    useEffect(() => {
        const fetchAdmitCards = async () => {
            try {
                const response = await fetch('https://result.govtresult24.com/api/admitcards');
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                const sortedData = [...data].reverse(); // Reverse to get the most recent ones first
                setAdmitCards(sortedData); // Store sorted data in state
            } catch (error) {
                console.error('Error fetching admit cards:', error);
            } finally {
                setIsLoading(false); // Set loading to false after data has been fetched
            }
        };

        fetchAdmitCards();
    }, []);

    const handleRedirect = (applyLink) => {
        window.open(applyLink, '_blank');
    };

    const filteredAdmitCards = admitCards.filter(card =>
        card.jobTitle.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="max-w-6xl mx-auto mt-10 p-6 bg-gradient-to-br from-gray-50 to-gray-100 rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold mb-6 text-center text-blue-700 drop-shadow-md">Admit Cards</h1>
            <div className="mb-6">
                <div className="mb-6 flex justify-center">
                    <div className="flex items-stretch">
                        <input
                            type="text"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            placeholder="Search by job title"
                            className="border border-gray-300 rounded-l-lg py-3 px-4 bg-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                        <button
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded-r-lg transition duration-300"
                            style={{ height: "50px" }}
                        >
                            Search
                        </button>
                    </div>
                </div>
            </div>

            {/* Show loading text or spinner if isLoading is true */}
            {isLoading ? (
                <div className="flex justify-center items-center space-x-2">
                    <div className="w-8 h-8 border-4 border-blue-600 border-dashed rounded-full animate-spin"></div>
                    <p className="text-lg text-blue-700">Loading admit cards...</p>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    {filteredAdmitCards.length > 0 ? (
                        filteredAdmitCards.map((card, index) => (
                            <div key={index} className="p-6 border border-gray-300 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl">
                                <h2 className="text-xl font-semibold text-blue-800">{card.jobTitle}</h2>
                                <button
                                    onClick={() => handleRedirect(card.applyLink)}
                                    className="mt-4 w-full bg-gradient-to-r from-blue-500 to-blue-600 hover:bg-gradient-to-l text-white font-bold py-2 px-4 rounded transition duration-300 shadow-md hover:shadow-lg"
                                >
                                    Get Admit Card
                                </button>
                            </div>
                        ))
                    ) : (
                        <p>No admit cards found.</p>
                    )}
                </div>
            )}
        </div>
    );
};

export default AdmitCardList;
