import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Itemadminn = () => {
  const [title1, setTitle1] = useState('');
  const [link1, setLink1] = useState('');
  const [title2, setTitle2] = useState('');
  const [link2, setLink2] = useState('');
  const [pdfTitle, setPdfTitle] = useState('');
  const [pdf, setPdf] = useState(null);
  const [allItems, setAllItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [editItemId, setEditItemId] = useState(null);
  const [jobs, setJobs] = useState([]);
  const [selectedjobTitle2, setSelectedjobTitle2] = useState('');
  const [dynamicFields, setDynamicFields] = useState([{ title: '', link: '' }]);

  useEffect(() => {
    const fetchJobsAndItems = async () => {
      try {
        const [jobsResponse, itemsResponse] = await Promise.all([
          axios.get('https://result.govtresult24.com/api/job2'),
          axios.get('https://result.govtresult24.com/api/items'),
        ]);
        setJobs(jobsResponse.data);
        setAllItems(itemsResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchJobsAndItems();
  }, []);

  useEffect(() => {
    if (selectedjobTitle2) {
      const filtered = allItems.filter(item => item.jobTitle2 === selectedjobTitle2);
      setFilteredItems(filtered);
    } else {
      setFilteredItems(allItems);
    }
  }, [selectedjobTitle2, allItems]);

  const handleDynamicFieldChange = (index, e) => {
    const values = [...dynamicFields];
    values[index][e.target.name] = e.target.value;
    setDynamicFields(values);
  };

  const addDynamicField = () => {
    setDynamicFields([...dynamicFields, { title: '', link: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('dynamicFields', JSON.stringify(dynamicFields));
    formData.append('pdfTitle', pdfTitle);
    formData.append('jobTitle2', selectedjobTitle2);
    if (pdf) formData.append('pdf', pdf);

    try {
      let response;
      if (editItemId) {
        response = await axios.put(`https://result.govtresult24.com/api/items/${editItemId}`, formData);
      } else {
        response = await axios.post('https://result.govtresult24.com/api/items', formData);
      }

      if (response.status === 200 || response.status === 201) {
        const newData = response.data;
        if (editItemId) {
          setAllItems(prevItems => prevItems.map(item => (item._id === newData._id ? newData : item)));
        } else {
          setAllItems(prevItems => [...prevItems, newData]);
        }
      } else {
        console.error('Failed to submit data');
      }
    } catch (error) {
      console.error('Error submitting data:', error);
    }

    // Reset form state
    setDynamicFields([{ title: '', link: '' }]);
    setTitle1('');
    setLink1('');
    setTitle2('');
    setLink2('');
    setPdfTitle('');
    setPdf(null);
    setEditItemId(null);
  };

  const handleEdit = (item) => {
    if (item.dynamicFields) {
      setDynamicFields(item.dynamicFields);
      setPdfTitle(item.pdfTitle || '');
      setSelectedjobTitle2(item.jobTitle2 || '');
      setEditItemId(item._id);
    }
  };

  const handleDelete = async (itemId) => {
    try {
      const response = await axios.delete(`https://result.govtresult24.com/api/items/${itemId}`);
      if (response.status === 200) {
        setAllItems(prevItems => prevItems.filter(item => item._id !== itemId));
        setFilteredItems(filteredItems.filter(item => item._id !== itemId)); // Ensure filteredItems also updates
      } else {
        console.error('Failed to delete item');
      }
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Admin Panel</h1>
      <form onSubmit={handleSubmit} className="mb-6">
        <select
          name="selectedjobTitle2"
          value={selectedjobTitle2}
          onChange={(e) => setSelectedjobTitle2(e.target.value)}
          required
          className="mb-4 p-2 border rounded"
        >
          <option value="">Select Job Title</option>
          {jobs.map((job) => (
            <option key={job._id} value={job.jobTitle2}>
              {job.jobTitle2}
            </option>
          ))}
        </select>

        {dynamicFields.map((field, index) => (
          <div key={index} className="grid grid-cols-2 gap-4 mb-4">
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`title${index + 1}`}>Title {index + 1}</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={`title${index + 1}`}
                name="title"
                type="text"
                placeholder={`Title ${index + 1}`}
                value={field.title}
                onChange={(e) => handleDynamicFieldChange(index, e)}
                required
              />
            </div>
            <div>
              <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={`link${index + 1}`}>Link {index + 1}</label>
              <input
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id={`link${index + 1}`}
                name="link"
                type="text"
                placeholder={`Link ${index + 1}`}
                value={field.link}
                onChange={(e) => handleDynamicFieldChange(index, e)}
                required
              />
            </div>
          </div>
        ))}

        <button
          type="button"
          onClick={addDynamicField}
          className="bg-green-500 text-white py-2 px-4 rounded mb-4"
        >
          Add More Title/Link
        </button>

        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdfTitle">PDF Title</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pdfTitle"
            type="text"
            placeholder="PDF Title"
            value={pdfTitle}
            onChange={(e) => setPdfTitle(e.target.value)}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="pdf">PDF Upload</label>
          <input
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="pdf"
            type="file"
            accept="application/pdf"
            onChange={(e) => setPdf(e.target.files[0])}
          />
        </div>

        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded">
          {editItemId ? 'Update Item' : 'Add Item'}
        </button>
      </form>

      <div className="mt-8">
        <h2 className="text-xl font-bold mb-4">Items List</h2>
        <ul>
          {filteredItems.length > 0 ? (
            filteredItems.map((item) => (
              <li key={item._id} className="mb-4 p-4 border rounded bg-gray-50">
                {item.dynamicFields && item.dynamicFields.length > 0 ? (
                  item.dynamicFields.map((field, index) => (
                    <div key={index} className="mb-4">
                      <h3 className="font-semibold">{field.title}</h3>
                      <a href={field.link} target="_blank" rel="noopener noreferrer" className="text-blue-500">Link</a>
                    </div>
                  ))
                ) : null}
                {item.pdfTitle && (
                 <div className="mb-4">
                 <h3 className="font-semibold">{item.pdfTitle}</h3>
                 {item.pdf && (
                   <a
                     href={`https://result.govtresult24.com/${item.pdf}`}  // Adjust path according to your static files setup
                     download={item.pdfTitle}
                     className="text-blue-500"
                   >
                     Download PDF
                   </a>
                 )}
               </div>
               
                )}
                <button onClick={() => handleEdit(item)} className="bg-yellow-500 text-white py-2 px-4 rounded mr-2">Edit</button>
                <button onClick={() => handleDelete(item._id)} className="bg-red-500 text-white py-2 px-4 rounded">Delete</button>
              </li>
            ))
          ) : (
            <p>No items available.</p>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Itemadminn;
