import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroResult = () => {
    const [admitCards, setAdmitCards] = useState([]);
    const [visibleResults, setVisibleResults] = useState(7); // Control initial number of results shown
    const navigate = useNavigate(); // Initialize navigate

    useEffect(() => {
        const fetchAdmitCards = async () => {
            try {
                const response = await fetch("https://result.govtresult24.com/api/jobListings");
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                const data = await response.json();

                // Reverse or sort data to make the latest entry appear at the top
                const reversedData = [...data].reverse(); // Reverses the order of the array
                console.log(reversedData); // Log reversed data for debugging
                setAdmitCards(reversedData); // Store reversed data in state
            } catch (error) {
                console.error('Error fetching admit cards:', error);
            }
        };

        fetchAdmitCards();
    }, []);

    const handleRedirect = (applyLink) => {
        window.open(applyLink, '_blank'); // Open the link in a new tab
    };

    const handleViewMore = () => {
        navigate('/result'); // Navigate to the /result page
    };

    return (
        <div className="container mx-auto p-4 pt-6 mt-10 bg-white  rounded-lg shadow-lg border border-gray-300 ">
            <h1 className="text-5xl font-extrabold mb-8 text-center text-blue-600 drop-shadow-md">Result</h1>
            <hr className="w-full border-t-2 border-gray-300 mb-8" />
            <div className="grid grid-cols-1 gap-4"> 
                {admitCards.slice(0, visibleResults).map((card, index) => (
                    <div key={index} className="bg-white p-2 rounded-lg shadow-lg border border-gray-300 hover:bg-gray-100" >
                        <h2 className="text-lg font-bold text-blue-700">{card.listingTitle}</h2>
                        <button
                            onClick={() => handleRedirect(card.applyLink)}
                            className="mt-2 bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                        >
                            Check Result
                        </button>
                    </div>
                ))}

                {visibleResults < admitCards.length && ( // Show "View More" if there are more results
                    <div className="w-full text-end mt-6">
                        <button 
                            onClick={handleViewMore} 
                            className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                        >
                            View More
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default HeroResult;
